import request from "./request";
import veriRequest from "./requestVeri";
import chainRequest from "./requestChain";
import axios from "axios";
import global_ from "../config/global";

const http = {
  /**
   * methods: 请求
   * @param url 请求地址
   * @param params 请求参数
   */
  get(url, params) {
    const config = {
      method: "get",
      url: url,
    };
    if (params) config.params = params;
    return request(config);
  },
  post(url, params) {
    const config = {
      method: "post",
      url: url,
    };
    if (params) config.data = params;
    return request(config);
  },
  put(url, params) {
    const config = {
      method: "put",
      url: url,
    };
    if (params) config.params = params;
    return request(config);
  },
  delete(url, params) {
    const config = {
      method: "delete",
      url: url,
    };
    if (params) config.params = params;
    return request(config);
  },
  veriGet(url, params) {
    const config = {
      method: "get",
      url: url,
    };
    if (params) config.params = params;
    return veriRequest(config);
  },
  veriPost(url, params) {
    const config = {
      method: "post",
      url: url,
    };
    if (params) config.data = params;
    return veriRequest(config);
  },
  chainGet(url, params) {
    const config = {
      method: "get",
      url: url,
    };
    if (params) config.params = params;
    return chainRequest(config);
  },
  chainPost(url, params) {
    const config = {
      method: "post",
      url: url,
    };
    if (params) config.data = params;
    return chainRequest(config);
  },
  uploadFiles(url, data) {
    return axios({
      method: "post",
      url: global_.BASE_URL + url,
      transformRequest: [
        function (data, headers) {
          // 去除post请求默认的Content-Type
          delete headers["Content-Type"];
          return data;
        },
      ],
      data: data,
    });
  },
  npost(url, data) {
    return axios({
      method: "post",
      url: "http://192.168.1.219" + url,
      headers: {
        "Content-Type": "application/json", //配置请求头
      },
      data: data,
    });
  },
  Get(url, data) {
    return axios({
      method: "GET",
      url: global_.VERI_URL + url,
      headers: {
        "Content-Type": "application/json", //配置请求头
      },
      data: data,
    });
  },
};
//导出
export default http;
