<template>
  <div class="container">
    <div class="menu" style="width: 200px">
      <div class="logo">
        <img class="img-logo" src="@/assets/menuLogo.png" alt="" />
      </div>
      <div class="menuList">
        <div class="menuListTop" style="overflow-y: auto">
          <!-- active-text-color="#15a362" -->
          <el-menu
            :default-active="firstDefaultActive"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @select="handleselect"
            @close="handleClose"
            collapse-transition
            text-color="#252930"
          >
            <div v-for="item in routes" :key="item.path + item.name">
              <el-submenu
                :index="item.path"
                v-if="item.children"
                :style="{
                  borderBottom: item.name == '统计' ? '2px solid #E6E6E6' : '',
                }"
              >
                <template slot="title">
                  <i :class="item.icon"></i>
                  <span>统计</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item
                    v-for="v in item.children"
                    :key="v.path + v.name"
                    :index="v.path"
                  >
                    <i :class="item.icon"></i>
                    <span slot="title">{{ v.name }}</span>
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <div v-else>
                <el-menu-item
                  :index="item.path"
                  v-if="!item.show"
                  :style="{
                    borderBottom:
                      item.name == '统计' || item.name == '模板'
                        ? '2px solid #E6E6E6'
                        : '',
                  }"
                >
                  <i :class="item.icon"></i>
                  <span slot="title">{{ item.name }}</span>
                </el-menu-item>
              </div>

              <!-- <el-submenu v-if="item.children" :index="item.path">
                <template slot="title">
                  <i :class="item.icon"></i>
                  <span>{{ item.name }}</span>
                </template>
                <el-menu-item
                  v-for="val in item.children"
                  :key="val.path + val.name"
                  :index="val.path"
                >
                  <i :class="val.icon"></i>
                  <span slot="title">{{ val.name }}</span>
                </el-menu-item>
              </el-submenu> -->
            </div>
          </el-menu>
        </div>
        <div class="menuListBottom">
          <el-menu
            class="el-menu-vertical-demo"
            :default-active="firstDefaultActive"
            @select="handleselect"
            text-color="#252930"
          >
            <div v-for="item in routesBottom" :key="item.path + item.name">
              <el-menu-item :index="item.path" v-if="!item.IsSubAccount">
                <i :class="item.icon"></i>
                <span slot="title">{{ item.name }}</span>
              </el-menu-item>
            </div>
          </el-menu>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="header">
        <div
          style="float: left; line-height: 60px; color: red"
          v-if="userinfo.RemainingQuantity <= 30"
        >
          <span v-if="!userinfo.IsSubAccount">
            您的账户余额不足30条，请及时订购</span
          >
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: right;
            float: right;
            height: 60px;
          "
        >
          <el-button
            type="text"
            icon="el-icon-question"
            @click="handleselect('/help')"
            style="color: #000"
            >帮助</el-button
          >
          <el-button
            v-if="DemoLogin != 1"
            type="text"
            icon="el-icon-download"
            @click="handleselect('100')"
            style="color: #000; margin-right: 8px"
            >下载客户端</el-button
          >
          <el-button
            type="text"
            icon="el-icon-s-home"
            @click="handleselect('/information')"
            style="color: #000; margin-right: 8px"
            >账户</el-button
          >
          <el-dropdown
            v-if="!isClient"
            @command="handleCommand"
            style="float: right"
          >
            <span class="el-dropdown-link">
              <el-image
                class="pointer"
                style="width: 40px; height: 40px; border-radius: 50%"
                :src="circleUrl"
                :fit="'cover'"
              >
                <div slot="error" class="image-slot">
                  <el-image
                    style="width: 40px; height: 40px; border-radius: 50%"
                    :src="require('@/assets/logo.png')"
                    :fit="'cover'"
                  >
                  </el-image>
                </div>
              </el-image>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="loginOut">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-avatar
            v-else
            class="pointer"
            :size="40"
            :src="circleUrl"
          ></el-avatar>
        </div>
      </div>
      <div class="router">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import op from "../utils/OperatorCache";
import axios from "axios";
// 根据当前手机号判断系统显示menu的情况
import blacklist from "../config/noShowMenu";
export default {
  data() {
    return {
      userinfo: {},
      firstDefaultActive: "/information",
      routes: [],
      routesBottom: [
        { path: "/set", name: "签章设置", icon: "el-icon-setting" },
        // { path: "100", name: "下载客户端", icon: "el-icon-download" },
      ],
      circleUrl: "",
      IsSubAccount: false,
      isClient: false,
      DemoLogin: "",
      blacklist,
    };
  },
  mounted() {
    // let routerList = [
    //   // {
    //   //   path: "/information",
    //   //   name: "账户",
    //   //   icon: "el-icon-reading",
    //   // },
    //   { path: "/waybillCopy", name: "货单/服务", icon: "el-icon-tickets" },
    //   { path: "/waybill", name: "回执", icon: "el-icon-document-checked" },
    //   {
    //     path: "/ArrearList",
    //     name: "对账单",
    //     icon: "el-icon-document-copy",
    //   },
    //   {
    //     path: "/CollectMoney",
    //     name: "销账记录",
    //     icon: "el-icon-document-delete",
    //   },
    //   {
    //     path: "/Reconciliation",
    //     name: "对账函",
    //     icon: "el-icon-notebook-2",
    //     show: true,
    //   },
    //   {
    //     path: "/Overdue",
    //     name: "逾期",
    //     icon: "el-icon-paperclip",
    //   },
    //   {
    //     path: "/remind",
    //     name: "催签",
    //     icon: "el-icon-bell",
    //   },
    //   {
    //     path: "/group",
    //     name: "订阅",
    //     icon: "el-icon-share",
    //   },
    //   {
    //     path: "/chain",
    //     name: "存证",
    //     icon: "el-icon-receiving",
    //   },
    //   {
    //     path: "",
    //     name: "统计",
    //     icon: "el-icon-receiving",
    //     children: [
    //       {
    //         path: "/statistics",
    //         name: "发货统计",
    //         icon: "el-icon-receiving",
    //       },
    //       {
    //         path: "/statisticsSales",
    //         name: "收款统计",
    //         icon: "el-icon-receiving",
    //       },
    //       {
    //         path: "/statisticsSells",
    //         name: "销售统计",
    //         icon: "el-icon-receiving",
    //       },
    //       {
    //         path: "/statisticsBlance",
    //         name: "欠款统计",
    //         icon: "el-icon-receiving",
    //       },
    //       {
    //         path: "/statisticsOverdue",
    //         name: "逾期统计",
    //         icon: "el-icon-receiving",
    //       },
    //       {
    //         path: "/statisticsOverdueDays",
    //         name: "逾期天数统计",
    //         icon: "el-icon-receiving",
    //       },
    //     ],
    //   },
    //   {
    //     path: "/clienteleInfo",
    //     name: "客户",
    //     icon: "el-icon-phone-outline",
    //   },
    //   {
    //     path: "/ContactPerson",
    //     name: "签收人",
    //     icon: "el-icon-user",
    //   },
    //   {
    //     path: "/GoodsInfo",
    //     name: "货品/服务",
    //     icon: "el-icon-s-finance",
    //   },
    //   {
    //     path: "/Carrier",
    //     name: "承运/服务方",
    //     icon: "el-icon-truck",
    //   },
    //   { path: "/domprint", name: "模板", icon: "el-icon-s-order" },
    //   {
    //     path: "/UserManagement",
    //     name: "子账户",
    //     icon: "el-icon-user-solid",
    //     show: true,
    //   },

    //   // { path: "/help", name: "帮助", icon: "el-icon-question" },
    // ];
    // let routerList = [
    //   // {
    //   //   path: "/information",
    //   //   name: "账户",
    //   //   icon: "el-icon-reading",
    //   // },
    //   { path: "/waybillCopy", name: "货单/服务", icon: "el-icon-tickets" },
    //   { path: "/waybill", name: "回执", icon: "el-icon-document-checked" },
    //   // {
    //   //   path: "/ArrearList",
    //   //   name: "对账记录",
    //   //   icon: "el-icon-document-copy",
    //   // },
    //   // {
    //   //   path: "/CollectMoney",
    //   //   name: "销账记录",
    //   //   icon: "el-icon-document-delete",
    //   // },
    //   // {
    //   //   path: "/Reconciliation",
    //   //   name: "对账函",
    //   //   icon: "el-icon-notebook-2",
    //   //   show: true,
    //   // },
    //   // {
    //   //   path: "/Overdue",
    //   //   name: "逾期",
    //   //   icon: "el-icon-paperclip",
    //   // },
    //   {
    //     path: "/remind",
    //     name: "催签",
    //     icon: "el-icon-bell",
    //   },
    //   {
    //     path: "/group",
    //     name: "订阅",
    //     icon: "el-icon-share",
    //   },
    //   {
    //     path: "/chain",
    //     name: "存证",
    //     icon: "el-icon-receiving",
    //   },
    //   {
    //     path: "statistics",
    //     name: "统计",
    //     icon: "el-icon-receiving",
    //     // children:[{
    //     //   path: "/statistics",
    //     //   name: "发货统计",
    //     //   icon: "el-icon-receiving",
    //     // },
    //     // {
    //     //   path: "/statisticsSales",
    //     //   name: "收款统计",
    //     //   icon: "el-icon-receiving",
    //     // },
    //     // {
    //     //   path: "/statisticsSells",
    //     //   name: "销售统计",
    //     //   icon: "el-icon-receiving",
    //     // },
    //     // {
    //     //   path: "/statisticsBlance",
    //     //   name: "欠款统计",
    //     //   icon: "el-icon-receiving",
    //     // },
    //     // {
    //     //   path: "/statisticsOverdue",
    //     //   name: "逾期统计",
    //     //   icon: "el-icon-receiving",
    //     // },
    //     // {
    //     //   path: "/statisticsOverdueDays",
    //     //   name: "逾期天数统计",
    //     //   icon: "el-icon-receiving",
    //     // }]
    //   },
    //   {
    //     path: "/clienteleInfo",
    //     name: "客户",
    //     icon: "el-icon-phone-outline",
    //   },
    //   {
    //     path: "/clienteleInfo/group",
    //     name: "客户分组",
    //     icon: "el-icon-menu",
    //   },
    //   {
    //     path: "/clienteleInfo/solution",
    //     name: "查询方案",
    //     icon: "el-icon-s-opportunity",
    //   },
    //   {
    //     path: "/ContactPerson",
    //     name: "签收人",
    //     icon: "el-icon-user",
    //   },
    //   {
    //     path: "/GoodsInfo",
    //     name: "货品/服务",
    //     icon: "el-icon-s-finance",
    //   },
    //   {
    //     path: "/Carrier",
    //     name: "承运/服务方",
    //     icon: "el-icon-truck",
    //   },
    //   { path: "/domprint", name: "模板", icon: "el-icon-s-order" },
    //   {
    //     path: "/UserManagement",
    //     name: "子账户",
    //     icon: "el-icon-user-solid",
    //     show: true,
    //   },

    //   // { path: "/help", name: "帮助", icon: "el-icon-question" },
    // ];
    op.updateInfo(async() => {
      this.circleUrl = op.info.UserAvatarURL;
      this.userinfo = op.info;
      let userPhone = JSON.parse(sessionStorage.getItem("userInfo")).UserPhone;

      console.log("当前menu的手机号是否是行研的", userPhone);
      let routerList = null;

      if (this.userinfo.IsSubAccount){
       await this.$http
         .post('/BO/base_subaccountpermissions/GetDataList',
           {
             "Search": {
               "Condition": "UserId",
               "Keyword": this.userinfo.Id
             }
           }
         )
         .then((res) => {
           if (res.Success) {
             routerList = JSON.parse(res.Data[0].Permissions);
             // this.capacityList = JSON.parse(res.Data[0].Permissions).map(item => item.path);
           } else {
             this.$message.error(res.Msg)
           }
         })
     }
     else{
      if (blacklist.includes(userPhone)) {
        routerList = [
          // {
          //   path: "/information",
          //   name: "账户",
          //   icon: "el-icon-reading",
          // },
          { path: "/waybillCopy", name: "货单/服务", icon: "el-icon-tickets" },
          { path: "/waybill", name: "回执", icon: "el-icon-document-checked" },
          {
            path: "/remind",
            name: "催签",
            icon: "el-icon-bell",
          },
          // {
          //   path: "/ArrearList",
          //   name: "对账单",
          //   icon: "el-icon-document-copy",
          // },
          // {
          //   path: "/CollectMoney",
          //   name: "销账记录",
          //   icon: "el-icon-document-delete",
          // },
          {
            path: "/Reconciliation",
            name: "对账函",
            icon: "el-icon-notebook-2",
            show: true,
          },
          // {
          //   path: "/Overdue",
          //   name: "逾期",
          //   icon: "el-icon-paperclip",
          // },  订阅       
          {
            path: "/group",
            name: "客户组",
            icon: "el-icon-share",
          },
          // {
          //   path: "/chain",
          //   name: "存证",
          //   icon: "el-icon-receiving",
          // },
          {
            path: "",
            name: "统计",
            icon: "el-icon-receiving",
            children: [
              {
                path: "/statistics",
                name: "发货统计",
                icon: "el-icon-receiving",
              },
              // {
              //   path: "/statisticsSales",
              //   name: "收款统计",
              //   icon: "el-icon-receiving",
              // },
              // {
              //   path: "/statisticsSells",
              //   name: "销售统计",
              //   icon: "el-icon-receiving",
              // },
              // {
              //   path: "/statisticsBlance",
              //   name: "欠款统计",
              //   icon: "el-icon-receiving",
              // },
              // {
              //   path: "/statisticsOverdue",
              //   name: "逾期统计",
              //   icon: "el-icon-receiving",
              // },
              // {
              //   path: "/statisticsOverdueDays",
              //   name: "逾期天数统计",
              //   icon: "el-icon-receiving",
              // },
            ],
          },
          {
            path: "/clienteleInfo",
            name: "客户",
            icon: "el-icon-phone-outline",
          },
          {
            path: "/ContactPerson",
            name: "签收人",
            icon: "el-icon-user",
          },
          {
            path: "/GoodsInfo",
            name: "货品/服务",
            icon: "el-icon-s-finance",
          },
          {
            path: "/Carrier",
            name: "承运/服务方",
            icon: "el-icon-truck",
          },
          { path: "/domprint", name: "模板", icon: "el-icon-s-order" },
          {
            path: "/UserManagement",
            name: "子账户",
            icon: "el-icon-user-solid",
            show: true,
          },

          // { path: "/help", name: "帮助", icon: "el-icon-question" },
        ];
      } else {
        let HYdzdRouter = "";
        if (userPhone === "13810863588") {
          // 说明是行研系统登陆的， 更换对账函页面  其余显示原来的对账函页面
          HYdzdRouter = "/ReconciliationHydzd";
        } else {
          HYdzdRouter = "/Reconciliation";
        }

        routerList = [
          // {
          //   path: "/information",
          //   name: "账户",
          //   icon: "el-icon-reading",
          // },
          { path: "/waybillCopy", name: "货单/服务", icon: "el-icon-tickets" },
          { path: "/waybill", name: "回执", icon: "el-icon-document-checked" },
          {
            path: "/remind",
            name: "催签",
            icon: "el-icon-bell",
          },
          {
            path: "/ArrearList",
            name: "对账单",
            icon: "el-icon-document-copy",
          },
          {
            path: "/CollectMoney",
            name: "销账记录",
            icon: "el-icon-document-delete",
          },
          {
            path: HYdzdRouter,
            name: "对账函",
            icon: "el-icon-notebook-2",
            show: true,
          },
          {
            path: "/Overdue",
            name: "逾期",
            icon: "el-icon-paperclip",
          },
          {
            path: "/group",
            name: "客户组",
            icon: "el-icon-share",
          },
          {
            path: "/chain",
            name: "存证",
            icon: "el-icon-receiving",
          },
          {
            path: "",
            name: "统计",
            icon: "el-icon-receiving",
            children: [
              {
                path: "/statistics",
                name: "发货统计",
                icon: "el-icon-receiving",
              },
              {
                path: "/statisticsSales",
                name: "收款统计",
                icon: "el-icon-receiving",
              },
              {
                path: "/statisticsSells",
                name: "销售统计",
                icon: "el-icon-receiving",
              },
              {
                path: "/statisticsBlance",
                name: "欠款统计",
                icon: "el-icon-receiving",
              },
              {
                path: "/statisticsOverdue",
                name: "逾期统计",
                icon: "el-icon-receiving",
              },
              {
                path: "/statisticsOverdueDays",
                name: "逾期天数统计",
                icon: "el-icon-receiving",
              },
            ],
          },
          {
            path: "/clienteleInfo",
            name: "客户",
            icon: "el-icon-phone-outline",
          },
          {
            path: "/ContactPerson",
            name: "签收人",
            icon: "el-icon-user",
          },
          {
            path: "/GoodsInfo",
            name: "货品/服务",
            icon: "el-icon-s-finance",
          },
          {
            path: "/Carrier",
            name: "承运/服务方",
            icon: "el-icon-truck",
          },
          { path: "/domprint", name: "模板", icon: "el-icon-s-order" },
          {
            path: "/UserManagement",
            name: "子账户",
            icon: "el-icon-user-solid",
            show: true,
          },

          // { path: "/help", name: "帮助", icon: "el-icon-question" },
        ];

        console.log("匹配到这里了----", userPhone, HYdzdRouter, routerList);
      }
     }
      
      this.$http
        .post(
          "/Base_Manage/Base_User/GetRoute?phone=" + this.userinfo.UserPhone
        )
        .then((res) => {
          if (res.Success) {
            // 菜单显示
            if (res.Data.length > 0) {
              routerList.forEach((item) => {
                let idx = res.Data.findIndex((im) => im == item.name);
                if (idx !== -1) item.show = false;
              });
            }
          }
          // 子账户固定不显示子账户
          if (op.info.IsSubAccount) {
            let idx = routerList.findIndex((item) => item.name == "子账户");
            if (idx !== -1) routerList[idx].show = true;
          }
          this.routes = routerList;
        });
      this.isClient = sessionStorage.getItem("client");
      if (this.isClient) this.routesBottom[1].IsSubAccount = true;
    });
    let DemoLogin = sessionStorage.getItem("Demologin");
    this.DemoLogin = DemoLogin;
    console.log("============" + this.DemoLogin);
  },
  methods: {
    handleselect(e) {
      if (e === "100") {
        this.firstDefaultActive = "";
        var a = document.createElement("a"); // 创建一个<a></a>标签
        a.href = "https://www.yoojet.com/client/client_hzq2005.zip";
        a.style.display = "none"; // 隐藏a标签
        document.body.appendChild(a); // 将a标签追加到文档对象中
        a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
        a.remove(); // 一次性的，用完就删除a标签
        return;
      }
      this.firstDefaultActive = e;
      this.$router.push(e);
    },
    handleOpen(a) {
      console.log(a);
    },
    handleClose(a) {
      console.log(a);
    },
    handleCommand(command) {
      if (command == "loginOut") {
        // this.$router.replace('/Login')
        sessionStorage.removeItem("token");
        op.clear();
        let DemoLogin = sessionStorage.getItem("Demologin");
        console.log(DemoLogin);
        if (DemoLogin) {
          sessionStorage.removeItem("Demologin");
          window.open("http://chzq.yoojet.com/DemoLogin", "_self");
        } else {
          window.open("http://chzq.yoojet.com/", "_self");
        }
      }
    },
  },
  created() {
    this.firstDefaultActive = this.$route.path;
    // 获取IP地址
    axios.get("https://httpbin.org/ip").then((res) => {
      if (res.status == 200) {
        sessionStorage.setItem("ip", res.data.origin);
      }
    });
  },
  computed: {},
  watch: {},
};
</script>

<style lang="less" scoped>
.pointer {
  cursor: pointer;
}

.container {
  height: 100%;

  .menu {
    height: 100%;
    position: fixed;
    left: 0;
    box-shadow: 2px 0 2px #ddd;
    background: #fff;
    z-index: 100;

    .menuList {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100% - 60px);

      .menuListTop {
        height: calc(100% - 60px);
        // overflow-y: scroll;
        // overflow-x: hidden;
      }
    }

    .logo {
      display: flex;
      height: 60px;
      // justify-content: center;
      box-sizing: border-box;
      padding-left: 20px;
      align-items: center;
      .img-logo{
        height: 60px;
        width: auto;
        margin-top: 10px;
      }
      .imgBox {
        height: 40px;
        width: 40px;
        border-radius: 40px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 50px;
          width: 50px;
        }
      }
    }
  }

  .content {
    width: calc(100% - 200px);
    height: 100%;
    margin-left: 200px;

    .header {
      overflow: hidden;
      width: calc(100% - 200px);
      // display: flex;
      // justify-content: right;
      align-items: center;
      box-shadow: 0 2px 2px #ddd;
      background: #fff;
      height: 60px;
      box-sizing: border-box;
      padding: 0 20px;
      position: fixed;
      top: 0;
      right: 0;
      z-index: 99;
    }

    .router {
      box-sizing: border-box;
      padding: 20px;
      padding-top: 80px;
      background: #f5f6fe;
      min-height: 100%;
    }
  }
}
</style>
